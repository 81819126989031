
import { defineComponent, onMounted, reactive, ref } from 'vue';
import { ElMessage } from 'element-plus';
import PageHeader from '@/components/pageHeader/PageHeader.vue';
import Table from '@/components/table/Table.vue';
import hint from '../statics/svg/hint.svg';
import push from '../statics/svg/push.svg';
import Tags from '@/components/tag/Tags.vue';
import TextTip from '@/components/textTip/TextTip.vue';
import WaybillDetail from '../components/WaybillDetail.vue';
import { changeCarrier, getCarrierList, getList, pushInfo } from '../api/waybill';

export default defineComponent({
  name: 'Waybill',
  setup() {
    const keyword = ref('');
    const loading = ref(false);
    const pagination = reactive({
      total: 0,
      pageSize: 10,
      currentPage: 1,
    });
    const tableList = ref([]);

    const status = ref('0');
    const statusTypes = [
      {
        label: '全部状态',
        value: '0',
      },
      {
        label: '注册成功',
        value: '20',
      },
      {
        label: '注册失败',
        value: '30',
      },
      {
        label: '未注册',
        value: '10',
      },
    ];

    const pageChange = (cur: number) => {
      pagination.currentPage = cur;
      initData();
    };

    const search = () => {
      pagination.currentPage = 1;
      initData();
    };

    //常用承运商列表
    const carrierList = ref<any[]>([]);
    const carrierOptions = ref<any[]>([]);
    const getCarrierListData = async () => {
      let { data } = await getCarrierList();
      if (!data) return;
      carrierList.value = data;
      carrierOptions.value = [...data];
    };

    // 承运商搜索
    const courierFilter = query => {
      carrierOptions.value = carrierList.value.filter(item => {
        const reg = new RegExp(query, 'i');
        return reg.test(item.courier_name) || reg.test(item.courier_name_cn);
      });
    };

    //修改承运商
    const updateCarrierSelect = async (val: any) => {
      let item = carrierList.value.filter(item => item.courier_code === val.courier_code)[0];
      const params = new URLSearchParams();
      params.append('courier_name', item.courier_name);
      params.append('courier_code', val.courier_code);
      params.append('id', val.id);
      loading.value = true;
      await changeCarrier(params);
      initData();
    };

    const handlePush = async (row: any) => {
      try {
        await pushInfo({ id: row.id });
        ElMessage({
          type: 'success',
          message: '单号重新提交成功，请等待结果',
        });
      } catch (error) {
        initData();
      }
    };

    const initData = async () => {
      const { data } = await getList(
        {
          page: pagination.currentPage,
          page_size: pagination.pageSize,
          keyword: keyword.value,
          status: status.value,
        },
        loading,
      );
      tableList.value = data.list;
      pagination.total = Number(data.page_list.total);
    };

    onMounted(() => {
      initData();
      getCarrierListData();
    });

    //查看详情
    const orderInfo = ref();
    const isShowInfo = ref(false);
    const see_info = (row: any) => {
      orderInfo.value = {
        order_number: row.order_number,
        tracking_number: row.tracking_number,
        tracking_company: row.courier_name,
        delivery_status: row.delivery_status,
        delivery_substatus: row.delivery_substatus,
        pre_time_arrival_at: row.pre_time_arrival_at ? row.pre_time_arrival_at : 0,
        delivery_id: row.detail_id,
      };
      isShowInfo.value = true;
    };

    return {
      status,
      statusTypes,
      keyword,
      search,
      loading,
      pagination,
      tableList,
      pageChange,
      hint,
      push,
      carrierOptions,
      courierFilter,
      updateCarrierSelect,
      handlePush,
      see_info,
      isShowInfo,
      orderInfo,
    };
  },
  components: { PageHeader, Table, Tags, TextTip, WaybillDetail },
});
