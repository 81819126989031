
import { defineComponent, PropType } from 'vue';
export type placeMent =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
  | 'right'
  | 'right-start'
  | 'right-end';

export default defineComponent({
  name: 'TextTip',
  props: {
    placement: {
      type: String as PropType<placeMent>,
      default: 'top',
    },
    content: {
      type: String as PropType<string>,
    },
    tipWidth: {
      type: Number as PropType<number>,
      default: 360,
    },
    tag: {
      type: String as PropType<string>,
      default: 'span',
    },
    breakAll: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    isTitle: {
      type: Boolean as PropType<boolean>,
    },
    rows: {
      type: Number as PropType<number>,
      default: 1,
    },
  },
});
