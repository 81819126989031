
import { defineComponent, PropType } from 'vue';

type Status =
  | 'default'
  | 'init'
  | 'primary'
  | 'success'
  | 'danger'
  | 'finish'
  | 'abandon'
  | 'close';

export default defineComponent({
  name: 'Tags',
  emits: ['close'],
  props: {
    text: {
      type: String as PropType<string>,
    },
    type: {
      type: String as PropType<Status>,
    },
    width: {
      type: Number as PropType<number>,
    },
  },
  setup(_, { emit }) {
    const close = () => {
      emit('close');
    };

    return {
      close,
    };
  },
});
