import { Ref } from 'vue';
import request from '@/tools/request';

// 列表
export const getList = (params: any, loading: Ref<boolean>) => {
  return request({
    url: '/deliverytracking-order-list.html',
    loading,
    params,
  }) as Promise<any>;
};

// 重新推送
export const pushInfo = (params: any, loading?: Ref<boolean>) => {
  return request({
    url: '/deliverytracking-courier-retrytrack.html',
    loading,
    params,
  }) as Promise<any>;
};

// 切换物流公司
export const changeCarrier = (data: any, loading?: Ref<boolean>) => {
  return request({
    url: '/deliverytracking-order-changecarrier.html',
    loading,
    method: 'POST',
    data,
  }) as Promise<any>;
};

export const getCarrierList = (loading?: Ref<boolean>) => {
  return request({
    url: '/deliverytracking-courier-grouplist.html',
    loading,
  }) as Promise<any>;
};

// 运单详情
export const getTrackingInfo = (params: any, loading?: Ref<boolean>) => {
  return request({
    url: '/deliverytracking-order-detail.html',
    loading,
    params,
  }) as Promise<any>;
};
