
// import commonFunc from '@/utils/commonFunction';
// import { DateFormat } from '/@/utils/tools';
import { getTrackingInfo } from '../api/waybill';
// import { InfoData, Track } from '../types/waybillList';
import { defineComponent, ref, watch, PropType } from 'vue';
import Table from '@/components/table/Table.vue';
import useClipboard from 'vue-clipboard3';
import copyIcon from '../statics/svg/copy.svg';
import { ElMessage } from 'element-plus';

interface order {
  order_number: number;
  tracking_number: number;
  tracking_company: number | string;
  delivery_status: string;
  delivery_substatus: string;
  delivery_id: number;
  pre_time_arrival_at: number;
}
export default defineComponent({
  name: 'WaybillDetail',
  components: {
    Table,
  },
  props: {
    isVisibil: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    orderInfo: {
      type: Object as PropType<order>,
      default: () => {},
    },
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { toClipboard } = useClipboard();
    const list = ref<any>();
    const loading = ref(false);
    const isShow = ref(false);
    const activeNames = ref<number[]>([]);
    const jsonStr = ref(JSON.stringify(list.value?.origin_data));

    const copy = (text: string) => {
      try {
        toClipboard(text);
        ElMessage.success('复制成功');
      } catch (error) {
        ElMessage.error('复制失败');
      }
    };

    const getList = async (id: any) => {
      const { data } = await getTrackingInfo(
        {
          id,
        },
        loading,
      );
      if (!data) return;
      console.log(data);
      list.value = data;
      let open: number[] = [];
      data.providers.forEach((item, index) => {
        open.push(index);
      });
      activeNames.value = open;
    };

    watch(
      () => props.isVisibil,
      val => {
        isShow.value = val;
        if (val) {
          getList(props.orderInfo.delivery_id).then(() => {
            jsonStr.value = JSON.stringify(list.value?.origin_data);
          });
        }
      },
    );

    const close = () => {
      emit('close');
    };

    return {
      props,
      close,
      list,
      loading,
      isShow,
      activeNames,
      copy,
      jsonStr,
      copyIcon,
    };
  },
});
